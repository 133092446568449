<template>
  <div class="left_button_box">
    <div class="btns2">
      <router-link tag="button" class="btn-2 bg-green" style="border-radius: 10px 0 0 0;color: red"
                   :to="{path: '/recharge', query: {t: new Date().getTime()}}">
        <img src="../assets/images/icon/topbar/입금02.png"/> 충전
      </router-link>
      <router-link tag="button" class="btn-2 lr bg-green" style="color: blue"
                   :to="{path: '/exchange', query: {t: new Date().getTime()}}">
        <img src="../assets/images/icon/topbar/출금02.png"/> 환전
      </router-link>
      <router-link tag="button" class="btn-2 bg-green" style="border-radius: 0 10px 0 0;color: #000000"
                   :to="{path: '/customer_center', query: {t: new Date().getTime()}}">
        <img src="../assets/images/icon/topbar/문의02.png"/> 문의
      </router-link>
    </div>
    <div class="btns2" style="">
      <router-link tag="button" class="btn-2" :to="{path: '/sports', query: {t: new Date().getTime()}}">스포츠
      </router-link>
      <router-link tag="button" class="btn-2 lr" :to="{path: '/sports_special', query: {t: new Date().getTime()}}">
        스페셜
      </router-link>
      <router-link tag="button" class="btn-2 " :to="{path: '/sports_inplay', query: {t: new Date().getTime()}}">
        LIVE스포츠
      </router-link>

    </div>
    <div class="btns2" style="">
      <router-link tag="button" class="btn-2"
                   :to="{path: '/leisure/next_ring1m', query: {t: new Date().getTime()}}">미니게임
      </router-link>
      <router-link tag="button" to="/holdem" class="btn-2 lr">레볼루션 홀덤 </router-link>
      <router-link tag="button" class="btn-2" style="position: relative"
                   :to="{path: '/coupon', query: {type:2,t: new Date().getTime()}}">쿠폰
        <div
            style="position: absolute;top: 1px;right:18px;color: #ff973e;font-size: 10px;padding: 5px 8px;background-color: #0b1423;border-radius: 10px;line-height: 13px">
          {{ $store.state.userInfo.scoupon + $store.state.userInfo.gcoupon + $store.state.userInfo.dcoupon }}
        </div>

      </router-link>
    </div>
    <div class="btns2" style="">
      <router-link tag="button" class="btn-2" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">출석부
      </router-link>

      <router-link tag="button" class="btn-2 lr" :to="{path: '/notice', query: {t: new Date().getTime()}}">배팅규정
      </router-link>
      <router-link tag="button" class="btn-2" :to="{path: '/event', query: {t: new Date().getTime()}}">이벤트
      </router-link>
    </div>
    <div class="btns2" style="">
      <router-link tag="button" class="btn-2" :to="{path: '/recmember', query: {t: new Date().getTime()}}">
        추천인
      </router-link>
      <router-link tag="button" class="btn-2 lr" :to="{path: '/message', query: {t: new Date().getTime()}}">쪽지
      </router-link>
      <router-link tag="button" class="btn-2" :to="{path: '/sports_result', query: {t: new Date().getTime()}}">경기결과
      </router-link>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center">
      <router-link :to="{path: '/casinohonor', query: {type: 1}}" tag="div" style="width: 49.5%;cursor: pointer">
        <img src="../assets/images/left/icon/casino.jpg" style="width: 100%" alt="">
      </router-link>
      <router-link :to="{path: '/casinohonor', query: {type: 2}}" tag="div" style="width: 49.5%;cursor: pointer">
        <img src="../assets/images/left/icon/slot.jpg" style="width: 100%" alt="">
      </router-link>
    </div>
    <div style="display: flex;justify-content: space-between;align-items: center">
      <router-link tag="div" to="/holdem"  style="width: 100%;cursor: pointer;margin-top: 5px">
        <img src="../assets/images/left/icon/holdem.jpg" style="width: 100%" alt="">
      </router-link>
    </div>
  </div>
</template>

<script>
import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
import {changePoint, logout, weekpayback} from "../network/userRequest";
import {getRevoHoldemLoginUrl} from "@/network/revoHoldemRequest";

export default {
  name: "LeftButtonsComp",
  methods: {
    initUserInfo() {
      this.$store.dispatch('actionUserInfo')
    },
    weekpayback() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      weekpayback().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$store.dispatch('actionUserInfo')
          this.$swal({
            title: '주간 페이백 지급 성공',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },

  }
}
</script>

<style scoped>
.left_button_box {
  width: 100%;
  padding: 0;
}

.left_button_box .btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0px 0;
  border-bottom: 1px solid #d9cdb5;
}

.left_button_box .btns .btn-1 {
  width: 33.33%;
  height: 34px;
  background-color: var(--leftBarBtnBg);
  color: #e7e7e7;
  margin: 1px 0;
  border-radius: 0px;
  font-weight: bold;
}

.left_button_box .btns .btn-1 img {
  width: 20px;
  vertical-align: middle;
}

.left_button_box .btns2 .btn-2 img {
  width: 20px;
  vertical-align: middle;
}

.left_button_box .btns2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #141414;
  font-weight: bold;
}

.left_button_box .btns2 .btn-2 {
  width: 33.33%;
  height: 40px;
  border-bottom: 1px solid #303030;

  font-weight: bold;
  font-size: 12px;
}

.left_button_box .btns2 .lr {
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
}

.bg-green {
  background: linear-gradient(180deg, #28da50, #ecef46);
}

@media screen and (max-width: 1024px) {
  .left_button_box {
    display: none !important;
  }
}
</style>